<template>
  <el-select
    ref="select_element"
    :value="valueTitle"
    :clearable="clearable"
    :disabled="disabled"
    @clear="clearHandle"
  >
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree
        :class="multiple ? 'multipleTree' : 'soloTree'"
        @check="getKeys"
        :check-strictly="checkStrictly"
        :show-checkbox="multiple"
        :check-on-click-node="multiple"
        :default-expand-all="multiple"
        :default-checked-keys="valueMultiple"
        id="tree-option"
        ref="selectTree"
        :accordion="accordion"
        :data="companyTree"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        @node-click="handleNodeClick"
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
/**
 * 一般用于 新增__回显
 * 企业选择组件，可多选可单选，多选时父子不关联，不联动
 *
 */
export default {
  name: 'el-tree-select',

  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: 'id', // ID字段名
          label: 'name', // 显示名称
          children: 'subCompanyTrees' // 子级字段名
        }
      }
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    /* 初始值 */
    value: {
      type: Number,
      default: () => {
        return null
      }
    },
    companyTree: {
      type: Array,
      default: () => {
        return []
      }
    },
    /* 初始显示 */
    valueName: {
      type: String,
      default: () => {
        return null
      }
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    //父子关联
    checkStrictly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    valueNameMultiple: {
      type: String,
      default: () => {
        return ''
      }
    },
    valueMultiple: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  created () { },

  mounted () { },

  data () {
    return {
      valueId: this.value, // 初始值
      valueTitle: '',
      defaultExpandedKey: [],
      checkedNodes:[],
    }
  },

  methods: {
    // 多选时checked点击事件
    getKeys (data, checked) {
      // console.log('复选框', data, checked)
      let idS = [];
      let str = ''
      // if (checked.checkedNodes.length !== 0) {
      //   if (checked.checkedNodes.length === 1) {
      //     this.valueTitle = checked.checkedNodes[0][this.props.label]
      //   } else {
      //     let str = ''
      //     checked.checkedNodes.map((item) => {
      //       str += `${item[this.props.label]},
      //       `
      //     })
      //     this.valueTitle = str
      //   }
      // } else {
      //   this.valueTitle = ''
      // }
      //修改联动
      this.checkedNodes = [...checked.checkedNodes]
      if (checked.checkedNodes.length !== 0) {
          this.checkedNodes.forEach((item) => {
            let index = checked.checkedNodes.findIndex(
              (element) => item.parentId === element.id || item.parentId === element.id
            )
            if (index < 0) {
              idS.push(item.id)
              str += `${item[this.props.label]}`
            }
          })
          this.valueTitle = str
      } else {
        idS = [];
        this.valueTitle = ''
      }
      // console.log('ids',idS)
      // this.$emit('getValue', checked.checkedKeys)
      this.$emit('getValue', idS)
    },
    // 初始化值
    initHandle () {
      this.valueTitle = this.valueName // 初始化显示
      if (this.valueId) {
        this.defaultExpandedKey = [this.valueId] // 设置默认展开
      }
      this.$refs.selectTree.setCurrentKey(this.value) // 设置默认选中
      this.$emit('getValue', this.valueId)
      this.$emit('getName', this.valueTitle)
      this.initScroll()
    },
    // 初始化滚动条
    initScroll () {
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll(
          '.el-scrollbar .el-select-dropdown__wrap'
        )[0]
        const scrollBar = document.querySelectorAll(
          '.el-scrollbar .el-scrollbar__bar'
        )
        scrollWrap.style.cssText =
          'margin: 0px; max-height: none; overflow: hidden;'
        scrollBar.forEach((ele) => (ele.style.width = 0))
      })
    },
    // 切换选项
    handleNodeClick (node) {
      // console.log('切换选项', node)
      if (!this.multiple) {
        this.valueTitle = node[this.props.label]
        this.valueId = node[this.props.value]
        this.$emit('getValue', this.valueId)
        this.$emit('getName', this.valueTitle)
        this.defaultExpandedKey = []
        this.$refs.select_element.blur()
      }
    },
    // 清除选中
    clearHandle () {
      this.valueTitle = ''
      this.valueId = null
      this.defaultExpandedKey = []
      this.clearSelected()
      this.$emit('getValue', null)
      this.$emit('getName', null)
    },
    /* 清空选中样式 */
    clearSelected () {
      this.$refs.selectTree.setCheckedNodes([]) // 设置默认选中
      const allNode = document.querySelectorAll('#tree-option .el-tree-node')
      allNode.forEach((element) => element.classList.remove('is-current'))
    }
  },

  watch: {
    valueName () {
      this.valueId = this.value
      this.initHandle()
    },
    valueNameMultiple (val) {
      this.valueTitle = val
      this.$refs.selectTree.setCurrentKey(this.valueMultiple)
      this.clearSelected()
      this.$emit('getValue', this.valueMultiple)
      this.$emit('getName', this.valueTitle)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.soloTree >>> .is-current > .el-tree-node__content > .el-tree-node__label {
  /* color: #059370; */
  font-weight: 700;
}
.soloTree >>> .is-current .el-tree-node__children .el-tree-node__label {
  /* color: #059370; */
  font-weight: normal;
}

/* 开发禁用 */
/* .el-tree-node:focus>.el-tree-node__content{
    background-color:transparent;
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-tree-node__content:hover{
    background-color: #f5f7fa;
  } */
</style>
